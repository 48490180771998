<template>
  <app-timeline-item icon="BookIcon">
    <div class="mb-1 mb-sm-0">
      <h4>INVOICE</h4>
      <b-container>
        <b-row class="justify-content-md-left align-items-center mt-2">
          <b-col
            col
            lg="2"
          >
            <a
              v-if="!isInvoiceDownloading"
              :href="invoiceLink"
              target="_blank"
            >Download Here</a>
            <b-spinner
              v-else
              variant="primary"
              :label="$t('src.layouts.delivery-summary.timeline.dowloading')"
            />
          </b-col>
        </b-row>
      </b-container>
    </div>
  </app-timeline-item>
</template>

<script>
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  name: 'DownloadInvoice',
  components: {
    AppTimelineItem,
  },
  props: {
    invoiceLink: {
      type: String,
      default: '#',
    },
  },
  data() {
    return {
      isInvoiceDownloading: false,
    }
  },
}
</script>
