<template>
  <div v-if="!isLoading">
    <div class="card box-shadow-none mt-2 d-lg-block d-none">
      <div class="card-body">
        <div class="my-2 text-center" style="position: relative">
          <div style="position: relative; padding-left: 100px">
            <div class="bg-primary line" />
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <div class="track d-flex flex-column align-items-center">
              <div
                :class="[
                  'timeline-item-icon',
                  'd-flex',
                  'align-items-center',
                  'justify-content-center',
                  'rounded-circle',
                  'border p-1 mb-1 icon',
                  order.status.value >= 1 && order.status.value != 10
                    ? 'active-tracking border-primary'
                    : 'bg-white',
                ]"
              >
                <feather-icon color="#ea5455" size="25" icon="ShoppingCartIcon" />
              </div>
              <h6 class="mb-0">Order Placed</h6>
            </div>
            <div class="track d-flex flex-column align-items-center">
              <div
                :class="[
                  'timeline-item-icon',
                  'd-flex',
                  'align-items-center',
                  'justify-content-center',
                  'rounded-circle',
                  'border p-1 mb-1 icon',
                  order.status.value >= 2 && order.status.value != 10
                    ? 'active-tracking border-primary'
                    : 'bg-white',
                ]"
              >
                <feather-icon color="#ea5455" size="25" icon="LoaderIcon" />
              </div>
              <h6 class="mb-0">Processing</h6>
            </div>
            <div class="track d-flex flex-column align-items-center">
              <div
                :class="[
                  'timeline-item-icon',
                  'd-flex',
                  'align-items-center',
                  'justify-content-center',
                  'rounded-circle',
                  'border p-1 mb-1 icon',
                  order.status.value >= 6 && order.status.value != 10
                    ? 'active-tracking border-primary'
                    : 'bg-white',
                ]"
              >
                <feather-icon color="#ea5455" size="25" icon="InboxIcon" />
              </div>
              <h6 class="mb-0">Picked Up</h6>
            </div>
            <div class="track d-flex flex-column align-items-center">
              <div
                :class="[
                  'timeline-item-icon',
                  'd-flex',
                  'align-items-center',
                  'justify-content-center',
                  'rounded-circle',
                  'border p-1 mb-1 icon',
                  order.status.value >= 7 && order.status.value != 10
                    ? 'active-tracking border-primary'
                    : 'bg-white',
                ]"
              >
                <feather-icon color="#ea5455" size="25" icon="TruckIcon" />
              </div>
              <h6 class="mb-0">Out For Delivery</h6>
            </div>
            <div class="track d-flex flex-column align-items-center">
              <div
                :class="[
                  'timeline-item-icon',
                  'd-flex',
                  'align-items-center',
                  'justify-content-center',
                  'rounded-circle',
                  'border p-1 mb-1 icon',
                  order.status.value >= 8 && order.status.value != 10
                    ? 'active-tracking border-primary'
                    : 'bg-white',
                ]"
              >
                <feather-icon color="#ea5455" size="25" icon="CheckCircleIcon" />
              </div>
              <h6 class="mb-0">Delivered</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-row v-if="order.order_type !== 'CHARTERED'" class="mt-2">
      <b-col>
        <div class="card box-shadow-none">
          <div class="card-body">
            <h4 class="card-title font-weight-bolder mb-0">ORDER SUMMARY</h4>
            <div class="p-1">
              <h5 class="font-weight-bolder">Order ID : {{ order.reference_id }}</h5>
              <h5 class="font-weight-bolder">
                Status :
                <b-badge :variant="order.status.color_name">
                  {{ order.order_type === 'B2B' ? order.status.text : getStatusText(order.status) }}
                </b-badge>
                <b-badge v-if="order.received_at" class="ml-1" variant="success">
                  Order Received
                </b-badge>
              </h5>
              <h5 class="font-weight-bolder">
                Grand Total : {{ $helpers.formatTotal(order.price) }}
              </h5>
              <h5 class="font-weight-bolder">
                Order Placed Date : {{ $helpers.formatDateTime(order.date_added) }}
              </h5>
              <h5 class="font-weight-bolder">
                Delivery Date : {{ $helpers.formatDateTime(order.delivery_date) }}
              </h5>
            </div>
          </div>
        </div>
        <div class="card box-shadow-none">
          <div class="card-body">
            <h4 class="card-title font-weight-bolder mb-0">PRODUCT DETAILS</h4>
            <div class="p-1">
              <h5 class="font-weight-bolder">Quantity : {{ order.quantity }} box</h5>
              <h5 class="font-weight-bolder">Remarks : {{ order.remarks }}</h5>
              <h5 class="font-weight-bolder">Documents :</h5>
              <div class="d-flex mb-1">
                <b-button variant="danger" size="sm" type="button" @click.prevent="reprint('do')"
                  >Reprint DO</b-button
                >
                <b-button
                  variant="danger"
                  size="sm"
                  class="mx-1"
                  type="button"
                  @click.prevent="reprint('grn')"
                  >Reprint GRN</b-button
                >
                <b-button variant="danger" size="sm" type="button" @click.prevent="reprint('cover')"
                  >Reprint Cover Note</b-button
                >
              </div>
              <h5 class="font-weight-bolder">Barcode :</h5>
              <b-img
                :src="`${$barcodeUrl}${order.id}`"
                v-bind="barcode"
                rounded="0"
                alt="Barcode"
                class="d-inline-block"
              />
            </div>
          </div>
        </div>
        <div class="card box-shadow-none">
          <div class="card-body">
            <h4 class="card-title font-weight-bolder mb-0">ORIGIN</h4>
            <div class="p-1">
              <h5 class="font-weight-bolder">Sender's Name : {{ order.sender.name }}</h5>
              <h5 class="font-weight-bolder">Sender's Address : {{ order.sender.complete }}</h5>
              <h5 class="font-weight-bolder">Person In Charge : {{ order.sender.name }}</h5>
              <h5 class="font-weight-bolder">Phone Number : {{ order.sender.phone }}</h5>
            </div>
          </div>
        </div>
        <div class="card box-shadow-none">
          <div class="card-body">
            <h4 class="card-title font-weight-bolder mb-0">DESTINATION</h4>
            <div class="p-1">
              <h5 class="font-weight-bolder">Recipient's Name : {{ order.recipient.name }}</h5>
              <h5 class="font-weight-bolder">
                Recipient's Address : {{ order.recipient.complete }}
              </h5>
              <h5 class="font-weight-bolder">Person In Charge : {{ order.recipient.name }}</h5>
              <h5 class="font-weight-bolder">Phone Number : {{ order.recipient.phone }}</h5>
            </div>
          </div>
        </div>
        <div class="card box-shadow-none">
          <div class="card-body">
            <h4 class="card-title font-weight-bolder mb-0">SMARTTRUCK</h4>
            <div class="p-1">
              <h5 class="font-weight-bolder">
                Type of Vehicle : {{ order.vehicle ? order.vehicle.type : '' }}
              </h5>
              <h5 class="font-weight-bolder">
                Driver's Name :
                {{ order.driver ? $helpers.formatTextToCapitalized(order.driver.full_name) : '' }}
              </h5>
              <h5 class="font-weight-bolder">
                Company's Name :
                {{
                  order.driver && order.driver.company
                    ? $helpers.formatTextToCapitalized(order.driver.company.name)
                    : ''
                }}
              </h5>
            </div>
          </div>
        </div>
      </b-col>
      <b-col>
        <div class="card box-shadow-none">
          <div class="card-body">
            <h4 class="card-title font-weight-bolder">TRACKING SUMMARY</h4>
            <app-timeline>
              <app-timeline-item
                v-for="(summary, index) in order.logs"
                :key="index"
                :title="summary.title"
                :subtitle="summary.description"
                :time="summary.date_added"
                variant="warning"
              />
            </app-timeline>
          </div>
        </div>
      </b-col>
    </b-row>
    <div v-else>
      <div class="card box-shadow-none">
        <div class="card-body">
          <h4 class="card-title font-weight-bolder">TRACKING SUMMARY</h4>
          <app-timeline>
            <app-timeline-item
              v-for="(summary, index) in order.logs"
              :key="index"
              :title="getDescription(summary)"
              :time="summary.date_added"
              variant="warning"
            />
          </app-timeline>
        </div>
      </div>
      <OrderDetails />
    </div>
    <b-row>
      <b-col>
        <div class="card box-shadow-none">
          <div class="card-body">
            <h4 class="card-title font-weight-bolder mb-0">PROOF OF DELIVERY</h4>
            <ProofOfDelivery
              :drop-off-photo="order.delivered_photo"
              :pickup-photo="order.pickup_photo"
              :good-return-photo="order.good_return_photo"
            />
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { ProofOfDelivery } from '@/components/DeliverySummary'
import OrderDetails from '@/views/pages/inside/CharteredTruck/OrderTracker/OrderDetails.vue'

export default {
  name: 'OrderTrackingDetails',
  components: {
    AppTimeline,
    AppTimelineItem,
    ProofOfDelivery,
    OrderDetails,
  },
  props: {
    order: {
      type: Object,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      barcode: [
        {
          width: 100,
          height: 50,
        },
      ],
      proofOfDelivery: [
        {
          width: 200,
          height: 200,
        },
      ],
    }
  },
  methods: {
    async reprint(type) {
      let fileName = ''
      if (type === 'do') {
        fileName = 'delivery-order'
      }

      if (type === 'cover') {
        fileName = 'cover-note'
      }

      if (type === 'grn') {
        fileName = 'good-return-note'
      }

      const response = await this.$http.get(`/b2b/${type}/id/${this.order.id}`, {
        responseType: 'blob',
      })
      const fileURL = window.URL.createObjectURL(new Blob([response.data]))
      const fileLink = document.createElement('a')

      fileLink.href = fileURL
      fileLink.setAttribute('download', `${fileName}-${this.order.date_added}-${this.order.id}.pdf`)
      document.body.appendChild(fileLink)

      fileLink.click()
    },
    getDescription(summary) {
      if (summary.description === 'Order Arrived At Sender') {
        return 'Picked Up by Lorry'
      } else if (summary.description === 'Picked by Lorry') {
        return 'Arrived at Destination'
      } else {
        return summary.description
      }
    },
    getStatusText(order) {
      let statusText
      switch (order.value) {
        case 17:
          statusText = 'For Quote'
          break
        case 12:
          statusText = 'Quoted'
          break
        case 2:
          statusText = 'Accepted'
          break
        case 3:
          statusText = 'Picked Up'
          break
        case 4:
          statusText = 'Arrived at Origin'
          break
        case 5:
          statusText = 'In Transit'
          break
        case 6:
          statusText = 'Arrived at Destination Port'
          break
        case 7:
          statusText = 'Out For Delivery'
          break
        case 8:
          statusText = 'Delivered'
          break
        case 9:
          statusText = 'Request For Cancellation'
          break
        case 10:
          statusText = 'Cancelled'
          break
        case 14:
          statusText = 'Pending Payment'
          break
        default:
          statusText = order.text
      }
      return statusText
    },
  },
}
</script>
<style>
.line {
  height: 5px;
  position: absolute;
  top: 33px;
  width: 85%;
}
.track {
  z-index: 1;
  width: 15%;
}
.icon {
  width: 70px;
  height: 70px;
}
.active-tracking {
  background-color: #ea9fad;
}
</style>
