<template>
  <app-timeline-item icon="InfoIcon">
    <div class="mb-1 mb-sm-0">
      <h4>INFORMATION</h4>
      <b-container class="d-flex align-items-center">
        <div class="flex-grow-1">
          <b-row class="justify-content-md-left mt-2 align-items-center">
            <b-col
              col
              lg="3"
            >
              <h6>PO Number</h6>
            </b-col>
            <b-col
              cols="12"
              md="auto"
            >
              <h6>
                {{ orderData.po_number }}
              </h6>
            </b-col>
          </b-row>
          <b-row class="justify-content-md-left mt-2 align-items-center">
            <b-col
              col
              lg="3"
            >
              <h6>PO Expiry Date</h6>
            </b-col>
            <b-col
              cols="12"
              md="auto"
            >
              <h6>
                {{ $helpers.formatDate(orderData.po_expiry_date) }}
              </h6>
            </b-col>
          </b-row>
          <b-row class="justify-content-md-left mt-2 align-items-center">
            <b-col
              col
              lg="3"
            >
              <h6>Invoice Number</h6>
            </b-col>
            <b-col
              cols="12"
              md="auto"
            >
              <h6>
                {{ orderData.invoice_number }}
              </h6>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </div>
  </app-timeline-item>
</template>
<script>
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  name: 'YourInformation',
  components: {
    AppTimelineItem,
  },
  props: {
    orderData: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
