<template>
  <app-timeline-item icon="MapPinIcon">
    <div class="mb-1 mb-sm-0">
      <h4>ADDRESSES</h4>
      <b-container>
        <b-row class="justify-content-md-left">
          <b-col
            col
            lg="6"
            class="p-0"
          >
            <b-container>
              <b-row class="justify-content-md-left mt-2">
                <b-col
                  col
                  lg="6"
                >
                  <h6>Pick Up Address</h6>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                  class="white-space-normal"
                >
                  <b-card class="custom-card">
                    {{ pickUpAddress }}
                  </b-card>
                </b-col>
              </b-row>
              <b-row class="justify-content-md-left mt-2">
                <b-col
                  col
                  lg="6"
                >
                  <h6>Drop Off Address</h6>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-card class="custom-card">
                    {{ dropOffAddress }}
                  </b-card>
                </b-col>
              </b-row>
            </b-container>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </app-timeline-item>
</template>

<script>
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  name: 'AddressesTimelineItem',
  components: {
    AppTimelineItem,
  },
  props: {
    pickUpAddress: {
      type: String,
      default: null,
    },
    dropOffAddress: {
      type: String,
      default: null,
    },
  },
}
</script>
