<template>
  <app-timeline-item icon="CalendarIcon">
    <div class="mb-1 mb-sm-0">
      <h4>DATE</h4>
      <b-container class="d-flex align-items-center">
        <div class="flex-grow-1">
          <b-row class="justify-content-md-left align-items-center mt-2">
            <b-col
              col
              lg="3"
            >
              <h6>Order Date</h6>
            </b-col>
            <b-col
              cols="12"
              md="auto"
            >
              <h6>{{ $helpers.formatDateTime(orderData.date_added) }}</h6>
            </b-col>
          </b-row>
          <b-row class="justify-content-md-left align-items-center mt-2">
            <b-col
              col
              lg="3"
            >
              <h6>Delivery Date</h6>
              <p>&nbsp;</p>
            </b-col>
            <b-col
              cols="12"
              md="auto"
            >
              <h6>
                {{ $helpers.formatDateTime(orderData.delivery_date) }}
              </h6>
              <p><small>Time of delivery will depend on delivery routes, weather and traffic conditions.</small></p>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </div>
  </app-timeline-item>
</template>
<script>
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  name: 'DateTimelineItem',
  components: {
    AppTimelineItem,
  },
  props: {
    orderData: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
